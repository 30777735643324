import React from "react"

const PageBanner = ({ pageTitle, subHeading, pageSubTitle, pageImage }) => {
  return (
    <div className="page-title-area">
      <div className="container">
        <div className="page-title-content">
          {pageImage ? (
            <div className="page-title-image">
              <img src={pageImage} alt="page-image" />
            </div>
          ) : (
            ""
          )}
          <div className="page-title-text">
            <h2>{pageTitle}</h2>
            <h3>{subHeading}</h3>
            <p>{pageSubTitle}</p>
          </div>
        </div>
      </div>

      {/* <div className="shape-img1">
        <img src={shape1} alt="banner" />
      </div>
      <div className="shape-img2">
        <img src={shape2} alt="banner" />
      </div>
      <div className="shape-img3">
        <img src={shape3} alt="banner" />
      </div> */}
    </div>
  )
}

export default PageBanner
