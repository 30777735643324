import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import CTABanner from "../components/Common/CTABanner"
import Footer from "../components/App/Footer"
import SEO from "../components/App/SEO"

const Competition  = () => {
  return (
    <Layout>
      <SEO title="Oxygen Home Loans Competition" />
      <Navbar />
      <PageBanner
        pageTitle="WIN $1,000 with Oxygen Home Loans Competition "
        pageSubTitle=""
      />
      <section className="promotion-area pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="promotion-content">
                <h4>Competition Summary</h4>
                <h5 className="pt-lg-3 pt-md-0 mb-1">Trade Promotion:	</h5>
                <p>
                    The Promoter will give away $1,000 in a random prize draw giveaway 
                </p>
                <h5 className="pt-lg-3 pt-md-0 mb-1">Promoter:	 </h5>
                <p>Oxygen Broking Services Pty Ltd (ABN 46 103 177 377) trading as Oxygen Home Loans of Level 3, 28 O’Connell St Sydney NSW 2000.   www.oxygen.com.au Telephone number 1300 855 699 </p>
                <h5 className="pt-lg-3 pt-md-0 mb-1">Start Date:  </h5>
                <p>Friday 1st April 2022 at 10am AEDST​</p>
                <h5 className="pt-lg-3 pt-md-0 mb-1">End Date:  </h5>
                <p>Thursday 30th June 2022 at 10:00pm AEST​​</p> 
                <h5 className="pt-lg-3 pt-md-0 mb-1">Entrants:	 </h5>
                <p>Entry is only open to Australian residents who are 18 years and over. </p>
                <p>Directors, employees and their Immediate Family Members of the Promoter, it’s related bodies corporate and any Real Estate Agencies or companies associated with the Promoter or the Promotion are not eligible to enter. Immediate Family Member means any of the following: spouse, ex-spouse, de-facto spouse, child or step-child (whether natural or by adoption), parent, step-parent, grandparent, step-grandparent, uncle, aunt, niece, nephew, brother, sister, step-brother, step-sister or first cousin. </p>
                <h5 className="pt-lg-3 pt-md-0">Prize description	 </h5>
                <p>A cash prize valued at $1,000 that will be paid via electronic funds transfer to the winner’s nominated bank account within 14 days of receiving the winner’s confirmed prize acceptance.</p> 
                <h5 className="pt-lg-3 pt-md-0">How to enter	</h5> 
                <ol>
                  <li>Attend a registered real estate open house during the promotion period; and </li>
                  <li>Provide the Real Estate Agent your correct contact details as part of the property inspection; and </li>
                  <li>Have a valid smartphone and click on the SMS or email link provided following the open for inspection; and </li>
                  <li>Follow the prompts and answer the questionnaire to the end; and</li>
                  <li>Agree to the Competition Terms and Conditions </li>
                </ol>
                <h5 className="pt-lg-3 pt-md-0">Multiple Entries </h5>
                <p>Entrants are permitted to submit multiple entries during the Promotional Period only if entry meets all requirements (If an entrant enters twice, they must have visited 2 registered open house inspections at different locations or on different dates). </p>
                <h5 className="pt-lg-3 pt-md-0">Winer Selection </h5>
                <p>The winner of a drawn prize will be determined by chance. The draw will take place at  Oxygen Home Loans, Level 3, 28 O’Connell St Sydney NSW 2000 at 11:00am AEST on Monday April 4th 2022 by using random selection. </p>
                <p>The Draw (and any unclaimed prize redraw) will be conducted in the presence of an independent scrutineer. </p>
                <p>The first valid entry drawn by random electronic selection will be the winner of the Prize. </p>
                <p>All reasonable attempts will be made to contact the winner. The winner will be advised in writing. The prize will retained until claimed by the winner. All unsuccessful entrants will be advised by email. </p>
              </div>
              <div className="promotion-content">
              <h4 className="pt-lg-3 pt-md-0">Terms and Conditions </h4>
                <h5>Entry into the Promotion 	</h5>
                <ol>
                  <li>The Promotion Details above and all Detailed Terms below form part of these Terms and Conditions. The Entrant agrees and acknowledges that they have read these Terms and Conditions </li>
                  <li>It is a condition of entry that all Terms and Conditions are accepted as final and the Entrant agrees to abide by these Terms and Conditions. Submission of an entry is deemed to be an acknowledgement by the Entrant that they have read these Terms and Conditions and accepted them. </li>
                  <li>Any capitalised terms used in these Terms and Conditions have the meaning given in the Promotion Details, unless stated otherwise. </li>
                  <li>The Promotion commences on the Start Date and runs for the Promotional Period ending on the End Date. </li>
                  <li>Valid entries by eligible Entrants will be accepted during the Promotional Period. No entries will be accepted after this time. Entries are deemed to be received at the time of receipt by the Promoter and not at the time of transmission or deposit by the Entrant. Records of the Promoter and its agencies are final and conclusive as to the time of receipt. The Promoter accepts no responsibility for any late, lost or misdirected entries due to technical disruptions, network congestion, social media platforms  faulting or for any other reason. </li>
                  <li>Illegible, incomprehensible and incomplete entries will be deemed invalid. An entry that is made on behalf of another person will also be deemed invalid. </li>
                  <li>The Promoter reserves the right to disqualify any entry which, in the opinion of the Promoter, includes objectionable content, profanity, inflammatory, offensive or defamatory comments, or which breach any law or infringes any third party rights, including intellectual property rights.</li>
                  <li>The Promoter reserves the right to disqualify any Entrant who tampers with the entry process or who submits an entry that is not in accordance with these Terms and Conditions or who has, in the opinion of the Promoter, engaged in conduct in entering the Promotion which is fraudulent, misleading, deceptive or generally damaging to the goodwill or reputation of the Promotion or Promoter. </li>
                  <li>The Promoter reserves the right, at any time, to validate and check the authenticity of entries and entrant's details including the right to request proof of an entrant's identity, age and residency. In the event that a winner cannot provide suitable proof, the winner will forfeit the prize in whole and no substitute will be offered.</li>
                  <li>The Promoter reserves the right to disqualify an Entrant or entries in the event of non-compliance with these Terms and Conditions. In the event that there is a dispute concerning the conduct of the Promotion, the decision of the Promoter is final and binding on each Entrant and no correspondence will be entered into. </li>
                  <li>An Entrant who uses any automated entry software or any other mechanical or electronic means that allows an individual to automatically enter repeatedly is prohibited and may render all entries submitted by that individual invalid. </li>
                  <li>No entry fee is charged by the Promoter to enter the Promotion. Where entry is allowed online, there is no additional cost to enter the Promotion other than any cost paid by the Entrant to access the website or social media platform of entry via their internet service provider. </li>
                  <li>Should an Entrant’s contact details change during the Promotion, it is the Entrant’s responsibility to notify the Promoter. A request to access or modify any information provided in an entry should be directed to the Promoter. </li>
                  <li>If for any reason any aspect of this Promotion is not capable of running as planned, including by reason of computer virus, communications network failure, bugs, tampering, unauthorised intervention, fraud, power or technical failure, acts of God, civil unrest, strike, war, act of terrorism or any other cause beyond the control of the Promoter, the Promoter may in its sole discretion cancel, terminate, modify or suspend the Promotion and invalidate any affected entries, or suspend or modify a prize, subject to State or Territory regulation. </li>
                </ol>
                <h5 className="pt-lg-3 pt-md-0">Prize</h5>
                <ol>
                  <li>The winner will be notified by email together with further instructions on how to arrange the collection of their prize within 2 days of the winner being determined. </li>
                  <li>The prize will be awarded to the person named in the winning entry. If there is a dispute as to the identity of an Entrant or winner, the Promoter reserves the right, in its sole discretion, to determine the identity of the Entrant or winner. </li>
                  <li>If any winner chooses not to take their prize (or is unable to), or does not take or claim a prize by the time specified by the Promoter, they forfeit the prize and the Promoter is not obliged to substitute the prize or provide an alternative. </li>
                  <li>The details of the prize(s) are set out in Prize Description. </li>
                  <li>It is a condition of accepting the prize that the winner may be required to sign a legal release in a form determined by the Promoter in its absolute discretion. </li>
                </ol>
                <h5 className="pt-lg-3 pt-md-0">Personal Information, marketing and publicity </h5>
                <ol>
                  <li>As part of the Promotion, the Promoter will collect Entrants' personal information. If the Entrant does not provide their personal information as requested, they may be ineligible to enter or claim a prize in the Promotion. Submission of an entry is deemed to be consent by the Entrant to the Promoter collecting their personal information. </li>
                  <li>The Promoter will use the personal information to enable an Entrant to participate in the Promotion. </li>
                  <li>The Promoter may disclose Entrants' personal information to third parties including its contractors and agents, prize suppliers, Promotion sponsors and service providers to assist in conducting this Promotion and to the State and Territory lottery departments as required under the relevant lottery legislation. By entering this Promotion, the Entrant consents to the provision of such information to relevant third parties and the use of said information by those third parties. </li>
                  <li>Oxygen Home Loans may use Entrants’ personal information for future marketing purposes regarding its products, including contacting the Entrant electronically. </li>
                  <li>Personal information will be stored by the Promoter on a database in Australia </li>
                  <li>For the purposes of public statements and advertisements, the Promoter may only publish the winner's surname, initial and State/Territory or postcode of residence. </li>
                  <li>The Oxygen Home Loans privacy policy contains information about how an Entrant may access, update and seek correction of the personal information Oxygen Home Loans holds about them and how an Entrant may complain about any potential breach by the Promoter of the Australian Privacy Principles or any other Australian privacy laws and how such complaints will be dealt with. </li>
                  <li>By accepting the prize, the winner agrees to participate in and co-operate as required with all reasonable marketing and editorial activities relating to the Promotion, including (but not limited to) being recorded, photographed, filmed or interviewed and acknowledges that the Promoter may use any such marketing and editorial material without further reference or compensation to them. </li>
                  <li>The winner agrees not to sell or otherwise provide their story and or photographs related to the Promotion or being a winner of the Promotion to any other media organisation. </li>
                  <li>Any guarantee or warranty given in relation to a prize is in addition to any relevant statutory guarantees and warranties and nothing in these Terms and Conditions restricts, excludes or modifies or purports to restrict, exclude or modify any statutory consumer rights under any applicable law including the Competition and Consumer Act 2010 (Cth). </li>
                  <li>The Promoter and its associated agencies and companies will not be liable for any loss (including, without limitation, indirect, special or consequential loss or loss of profits), expense, damage, personal injury or death which is suffered or sustained (whether or not arising from any person's negligence or wilful misconduct) in connection with this Promotion or accepting, taking part in or using any prize (or recommendation), except for any liability which cannot be excluded by law (in which case that liability is limited to the minimum allowable by law). </li>
                  <li>Neither the Promoter, nor any Promotion Sponsor or third party prize supplier, or their associated companies is responsible, to the extent permitted by law, for acts of god, acts of terrorism or war (declared or undeclared) or other events beyond their control which prevent awarding or acceptance of the prize in accordance with the prize description. </li>
                  <li>The Promoter accepts no responsibility for any tax implications of this Promotion and Entrants must seek their own independent financial advice in regards to their own personal circumstances and any tax implications relating to the prize or acceptance of the prize. </li>
                  <li>Failure by the Promoter to enforce any of its rights at any stage does not constitute a waiver of these rights. </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CTABanner />
      <Footer />
    </Layout>
  )
}

export default Competition
